import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import { Container } from "../../components/Grid";
import {
  ScHelper as ScHelperSrc,
  ScTitle as ScTitleSrc,
  ScWhiteButton as ScWhiteButtonSrc,
  ScGrayButton as ScGrayButtonSrc,
} from "../../components/Shared";

const ScRoot = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;

  @media screen and (min-width: 992px) {
    margin-top: 210px;
    margin-bottom: 10rem;
  }
`;

const ScMain = styled.div`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const ScTitle = styled(ScTitleSrc)`
  svg {
    margin-right: 4px;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 24px;
  }
`;
const ScHelper = styled(ScHelperSrc)`
  margin-left: 0;
  max-width: 800px;
  font-size: 18px;
  color: #868f97;
`;

const ScWhiteButton = styled(ScWhiteButtonSrc)`
  margin-bottom: 12px;
  width: 220px;

  @media screen and (min-width: 992px) {
    width: auto;
    margin-right: 12px;
  }
`;

const ScGrayButton = styled(ScGrayButtonSrc)`
  width: 220px;

  svg {
    margin-right: 6px;
  }

  @media screen and (min-width: 992px) {
    width: auto;
  }
`;

const ScCover = styled(Img)`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;

  @media screen and (min-width: 992px) {
    margin-top: 96px;
    margin-bottom: 96px;
  }
`;

export const Institutional = ({ data }: any) => {
  return (
    <ScRoot>
      <Container>
        <ScMain>
          <ScTitle>
            <svg
              height="48"
              viewBox="0 0 39 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M38.5 22.202H20.902V0L0 33.798h17.598V56L38.5 22.202Z"
                fill="#fff"
              />
            </svg>
            PowerTrade Institutional
          </ScTitle>
          <ScHelper>
            PowerTrade is a one-stop shop for institutional trading firms. Our
            institutional offering is designed to meet the needs of professional
            users, with features such as professional-grade SPAN/portfolio
            margin, multi-collateral, and an automated RFQ workflow.
          </ScHelper>
          <div>
            <ScWhiteButton to="/contact/">Contact Sales</ScWhiteButton>
            <ScGrayButton
              href="https://api-docs-5180b.web.app/docs/intro/"
              target="_blank"
            >
              <svg
                width={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 10.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.5 9V7l-2.177-.363a5.463 5.463 0 0 0-.595-1.437l1.283-1.796-1.415-1.414L10.8 3.272a5.463 5.463 0 0 0-1.437-.595L9 .5H7l-.363 2.177a5.463 5.463 0 0 0-1.437.595L3.404 1.99 1.99 3.404 3.272 5.2a5.463 5.463 0 0 0-.595 1.437L.5 7v2l2.177.363c.131.512.332.994.595 1.437L1.99 12.596l1.414 1.414L5.2 12.728c.443.263.925.464 1.437.595L7 15.5h2l.363-2.177a5.463 5.463 0 0 0 1.437-.595l1.796 1.283 1.414-1.414-1.282-1.797c.263-.443.464-.925.595-1.437L15.5 9Z"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              API Documentation
            </ScGrayButton>
          </div>

          <ScCover fluid={data.institutional.childImageSharp.fluid} />
        </ScMain>
      </Container>
    </ScRoot>
  );
};
