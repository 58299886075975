import React from "react";
import styled from "styled-components";

import { Container } from "../../components/Grid";
import {
  ScHelper as ScHelperSrc,
  ScTitle as ScTitleSrc,
  ScRoot,
  ScHorizontalDiv as ScHorizontalDivSrc,
} from "../../components/Shared";

const ScHorizontalDiv = styled(ScHorizontalDivSrc)`
  @media screen and (min-width: 992px) {
    margin-bottom: 84px;
  }
`;

const ScMain = styled.div``;

const ScTitleIcon = styled.div`
  text-align: center;
  @media screen and (min-width: 992px) {
    margin-bottom: -16px;
  }
`;

const ScTitle = styled(ScTitleSrc)`
  text-align: center;
  svg {
    margin-right: 4px;
  }
`;
const ScHelper = styled(ScHelperSrc)`
  max-width: 750px;
  font-size: 16px;
  color: #868f97;

  @media screen and (min-width: 992px) {
    font-size: 18px;
  }
`;

const ScBody = styled.div`
  margin-top: 36px;

  @media screen and (min-width: 992px) {
    margin-top: 84px;
  }
`;

const ScFeatureList = styled.div`
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 992px) {
    display: flex;
    margin-bottom: 10px;
  }
`;
const ScFeatureItem = styled.div`
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
  max-width: 350px;
  padding: 24px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(247, 248, 248, 0.12);
  backdrop-filter: blur(7px);
  border-radius: 12px;
  margin-bottom: 12px;

  @media screen and (min-width: 992px) {
    margin-bottom: 0;
  }
`;
const ScFeatureItemCover = styled.div`
  text-align: center;
  img {
    width: 90%;
    margin-bottom: 12px;
  }
`;
const ScFeatureItemTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #ffffff;
  margin-bottom: 12px;

  svg {
    margin-right: 6px;
  }
`;
const ScFeatureItemContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: #868f97;
  margin-left: 30px;
`;

export const Edge = () => {
  return (
    <ScRoot>
      <Container>
        <ScHorizontalDiv />
        <ScMain>
          <ScTitleIcon>
            <svg
              width={200}
              viewBox="0 0 193 177"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#a)">
                <ellipse
                  cx="96.5"
                  cy="88.5"
                  rx="22.5"
                  ry="14.5"
                  fill="#B4F002"
                />
              </g>
              <g filter="url(#b)">
                <circle cx="95" cy="89" r="8" fill="#B4F002" fillOpacity=".2" />
              </g>
              <circle cx="94" cy="51" r="42" fill="#fff" fillOpacity=".04" />
              <circle
                cx="94"
                cy="51"
                r="41.5"
                stroke="#F7F8F8"
                strokeOpacity=".12"
              />
              <path
                d="M113.5 45.202H95.902V23L75 56.798h17.598V79L113.5 45.202Z"
                fill="url(#c)"
              />
              <defs>
                <filter
                  id="a"
                  x="0"
                  y="0"
                  width="193"
                  height="177"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="37"
                    result="effect1_foregroundBlur_77_491"
                  />
                </filter>
                <filter
                  id="b"
                  x="75"
                  y="69"
                  width="40"
                  height="40"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="6"
                    result="effect1_foregroundBlur_77_491"
                  />
                </filter>
                <linearGradient
                  id="c"
                  x1="94.25"
                  y1="23"
                  x2="94"
                  y2="91"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#fff" stopOpacity=".9" />
                  <stop offset="1" stopColor="#fff" stopOpacity=".67" />
                </linearGradient>
              </defs>
            </svg>
          </ScTitleIcon>
          <ScTitle>The institutional edge</ScTitle>
          <ScHelper>
            Get a competitive advantage with PowerTrade's extensive liquidity,
            professional-grade SPAN/portfolio margin, multi-collateral trading,
            institutional custody, and other features.
          </ScHelper>

          <ScBody>
            <ScFeatureList>
              <ScFeatureItem>
                <ScFeatureItemCover>
                  <img src="/images/v5/institutional/icon-01.png" />
                </ScFeatureItemCover>
                <ScFeatureItemTitle>
                  <svg
                    width={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="m4 9.675 6.88 3.972a.886.886 0 0 0 .231.093v7.705l-6.62-3.919a1 1 0 0 1-.491-.86V9.675Zm16-.106v7.097a1 1 0 0 1-.49.86l-6.621 3.918v-7.771a.823.823 0 0 0 .048-.026L20 9.569Z"
                      fill="#fff"
                    />
                    <path
                      opacity=".44"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.216 7.747a.999.999 0 0 1 .314-.262l7-3.728a1 1 0 0 1 .94 0l7 3.728c.095.05.18.116.253.191l-7.675 4.431a.885.885 0 0 0-.14.1.885.885 0 0 0-.139-.1l-7.553-4.36Z"
                      fill="#fff"
                    />
                  </svg>
                  Derivatives Suite
                </ScFeatureItemTitle>
                <ScFeatureItemContent>
                  A full derivatives suite. Trade options, perpetuals, futures
                  and spot, and hedge all in one place.
                </ScFeatureItemContent>
              </ScFeatureItem>

              <ScFeatureItem>
                <ScFeatureItemCover>
                  <img src="/images/v5/institutional/icon-02.png" />
                </ScFeatureItemCover>
                <ScFeatureItemTitle>
                  <svg
                    width={24}
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle opacity=".44" cx="12" cy="12.5" r="9" fill="#fff" />
                    <path
                      opacity=".44"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.736 21.496a9 9 0 0 1-8.733-9.245c.66.063 1.853.206 1.91.67.077.64 0 1.44.545 1.68s.701-.96 1.169-.64c.467.32 1.714.629 1.714 1.195 0 .566-.234 1.447 0 1.608.234.16 1.168 1.28 1.168 1.44 0 .161.33.975.231 1.287-.08.258-.491 1.06-.89 1.424a8.743 8.743 0 0 0 2.886.581ZM8.283 4.301A8.967 8.967 0 0 1 12 3.5a9 9 0 0 1 8.036 4.944c.034.78.028 1.447.2 1.689.877 1.227-2.137 2.071-1.679 3.928.202.819-2.004.559-2.543 1.325-.54.767-1.856.252-2.162.105-.305-.148-1.475.343-1.373-.492.102-.834.813-.883 1.577-1.227.763-.343 1.863-1.485 1.322-1.634-2.546-.702-3.414-3.16-3.414-3.16-.153-.029-.115-1.737-.776-1.786-.661-.05-1.018.196-1.984 0s-.763-.835-.915-2.21c-.034-.308-.033-.525-.006-.68Zm12.716 8.076L21 12.5a9 9 0 0 1-8.91 9c4.88-.05 8.844-4.112 8.91-9.123Z"
                      fill="#fff"
                    />
                  </svg>
                  Deep Liquidity
                </ScFeatureItemTitle>
                <ScFeatureItemContent>
                  Deep liquidity, which allows institutional traders to execute
                  large orders ensuring the best pricing.
                </ScFeatureItemContent>
              </ScFeatureItem>

              <ScFeatureItem>
                <ScFeatureItemCover>
                  <img src="/images/v5/institutional/icon-03.png" />
                </ScFeatureItemCover>
                <ScFeatureItemTitle>
                  <svg
                    width={24}
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity=".44"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="m4 4.5 7.631-1.43a2 2 0 0 1 .738 0L20 4.5v7.903a11 11 0 0 1-5.17 9.328l-2.3 1.438a1 1 0 0 1-1.06 0l-2.3-1.438A11 11 0 0 1 4 12.403V4.5Z"
                      fill="#fff"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.5 11a2.5 2.5 0 0 1 5 0v.5a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1V11ZM12 9.5a1.5 1.5 0 0 0-1.5 1.5v.5h3V11A1.5 1.5 0 0 0 12 9.5Z"
                      fill="#fff"
                    />
                  </svg>
                  Security & Custody
                </ScFeatureItemTitle>
                <ScFeatureItemContent>
                  Trade from self-custody with our integrated partners that
                  includes Copper, Bitgo, Anchorage and more.
                </ScFeatureItemContent>
              </ScFeatureItem>
            </ScFeatureList>

            <ScFeatureList>
              <ScFeatureItem>
                <ScFeatureItemCover>
                  <img src="/images/v5/institutional/icon-04.png" />
                </ScFeatureItemCover>
                <ScFeatureItemTitle>
                  <svg
                    width={24}
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15 20.023c0 .742.962 1.033 1.374.416l5.849-8.773a.75.75 0 0 0-.624-1.166H17V4.977c0-.742-.962-1.033-1.374-.416l-5.849 8.773a.75.75 0 0 0 .624 1.166H15v5.523Z"
                      fill="#fff"
                    />
                    <path
                      opacity=".44"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 7a1.5 1.5 0 0 1 1.5-1.5h5a1.5 1.5 0 0 1 0 3h-5A1.5 1.5 0 0 1 3 7Zm0 12a1.5 1.5 0 0 1 1.5-1.5h5a1.5 1.5 0 0 1 0 3h-5A1.5 1.5 0 0 1 3 19Zm-.5-7.5a1.5 1.5 0 0 0 0 3h4a1.5 1.5 0 0 0 0-3h-4Z"
                      fill="#fff"
                    />
                  </svg>
                  Competitive Fees
                </ScFeatureItemTitle>
                <ScFeatureItemContent>
                  A user-friendly fee structure with competitive taker fees and
                  attractive maker rebates across all products.
                </ScFeatureItemContent>
              </ScFeatureItem>

              <ScFeatureItem>
                <ScFeatureItemCover>
                  <img src="/images/v5/institutional/icon-05.png" />
                </ScFeatureItemCover>
                <ScFeatureItemTitle>
                  <svg
                    width={24}
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity=".44"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 21.5a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z"
                      fill="#fff"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13 5.562V4.5h1a1 1 0 1 0 0-2h-4a1 1 0 0 0 0 2h1v1.062a8.079 8.079 0 0 1 2 0ZM18.167 8.404l.783-.783a1 1 0 0 0-1.414-1.414l-.826.826a8.05 8.05 0 0 1 1.457 1.37ZM11.465 8.462A.5.5 0 0 1 11.963 8h.074a.5.5 0 0 1 .499.462l.423 5.5a.5.5 0 0 1-.499.538h-.92a.5.5 0 0 1-.498-.538l.423-5.5Z"
                      fill="#fff"
                    />
                  </svg>
                  Capital Efficient
                </ScFeatureItemTitle>
                <ScFeatureItemContent>
                  Trade efficiently with multi-collateral support and
                  professional grade SPAN/ Portfolio Margin.
                </ScFeatureItemContent>
              </ScFeatureItem>

              <ScFeatureItem>
                <ScFeatureItemCover>
                  <img src="/images/v5/institutional/icon-06.png" />
                </ScFeatureItemCover>
                <ScFeatureItemTitle>
                  <svg
                    width={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity=".44"
                      x="2"
                      y="3"
                      width="20"
                      height="18"
                      rx="2"
                      fill="#fff"
                    />
                    <path
                      d="M9.949 13.316A1 1 0 0 1 9 14H5a1 1 0 1 1 0-2h3.28l1.771-5.316c.317-.95 1.676-.897 1.92.073l1.844 7.378.79-1.582A1 1 0 0 1 15.5 12H19a1 1 0 1 1 0 2h-2.882l-1.724 3.447c-.415.83-1.639.696-1.864-.204l-1.667-6.669-.914 2.742Z"
                      fill="#fff"
                    />
                    <circle opacity=".44" cx="19" cy="6" r="1" fill="#fff" />
                  </svg>
                  High Performance
                </ScFeatureItemTitle>
                <ScFeatureItemContent>
                  Easy integration via API with low latency REST/Websocket
                  connectivity for high performance trading.
                </ScFeatureItemContent>
              </ScFeatureItem>
            </ScFeatureList>

            <ScFeatureList>
              <ScFeatureItem>
                <ScFeatureItemCover>
                  <img src="/images/v5/institutional/icon-07.png" />
                </ScFeatureItemCover>
                <ScFeatureItemTitle>
                  <svg
                    width={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity=".44"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 3H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-2v.5A1.5 1.5 0 0 1 14.5 5h-5A1.5 1.5 0 0 1 8 3.5V3Z"
                      fill="#fff"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13 2a1 1 0 1 0-2 0H9.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H13Z"
                      fill="#fff"
                    />
                    <rect
                      opacity=".44"
                      x="7"
                      y="10"
                      width="5"
                      height="2"
                      rx="1"
                      fill="#fff"
                    />
                    <rect
                      opacity=".44"
                      x="7"
                      y="14"
                      width="9"
                      height="2"
                      rx="1"
                      fill="#fff"
                    />
                  </svg>
                  Strategies/ RFQ
                </ScFeatureItemTitle>
                <ScFeatureItemContent>
                  Strategy trading and RFQ allows for risk management of entire
                  structures in one go with no minimum trade size.
                </ScFeatureItemContent>
              </ScFeatureItem>

              <ScFeatureItem>
                <ScFeatureItemCover>
                  <img src="/images/v5/institutional/icon-08.png" />
                </ScFeatureItemCover>
                <ScFeatureItemTitle>
                  <svg
                    width={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity=".44"
                      x="13"
                      y="4"
                      width="3"
                      height="16"
                      rx="1.5"
                      fill="#fff"
                    />
                    <rect
                      x="8"
                      y="9"
                      width="3"
                      height="11"
                      rx="1.5"
                      fill="#fff"
                    />
                    <rect
                      x="18"
                      y="11"
                      width="3"
                      height="9"
                      rx="1.5"
                      fill="#fff"
                    />
                    <rect
                      x="3"
                      y="13"
                      width="3"
                      height="7"
                      rx="1.5"
                      fill="#fff"
                    />
                  </svg>
                  Market Maker Program
                </ScFeatureItemTitle>
                <ScFeatureItemContent>
                  Token incentives, deep liquidity, and cutting-edge technology
                  to optimize your trading strategies.
                </ScFeatureItemContent>
              </ScFeatureItem>

              <ScFeatureItem>
                <ScFeatureItemCover>
                  <img src="/images/v5/institutional/icon-09.png" />
                </ScFeatureItemCover>
                <ScFeatureItemTitle>
                  <svg
                    width={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity=".44"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="m5 15-2 6.5 6.5-2L5 15Z"
                      fill="#fff"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M23 11.5a9.5 9.5 0 1 1-19 0 9.5 9.5 0 0 1 19 0ZM8 9a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2H9Z"
                      fill="#fff"
                    />
                  </svg>
                  VIP Support
                </ScFeatureItemTitle>
                <ScFeatureItemContent>
                  Get personalized and priority support from our expert team
                  with our VIP support program.
                </ScFeatureItemContent>
              </ScFeatureItem>
            </ScFeatureList>
          </ScBody>
        </ScMain>
      </Container>
    </ScRoot>
  );
};
