import React, { useState } from "react";
import styled from "styled-components";

import { Container } from "../../components/Grid";
import { ScTitle as ScTitleSrc, ScRoot } from "../../components/Shared";
import { ScHorizontalDiv as ScHorizontalDivSrc } from "../../components/Shared";

const ScHorizontalDiv = styled(ScHorizontalDivSrc)`
  @media screen and (min-width: 992px) {
    margin-top: 150px;
    margin-bottom: 150px;
  }
`;

const ScMain = styled.div`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const ScTitle = styled(ScTitleSrc)`
  text-align: center;

  @media screen and (min-width: 992px) {
    margin-bottom: 72px;
  }
`;

const ScQuestionList = styled.div`
  margin-top: 32px;

  @media screen and (min-width: 992px) {
    margin-top: 72px;
  }
`;

const ScExpander = styled.div`
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(247, 248, 248, 0.12);
  backdrop-filter: blur(7px);
  border-radius: 12px;
  padding: 20px 24px;
  margin-bottom: 16px;
  cursor: pointer;
  user-select: none;
`;

const ScExpanderTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;

  svg {
    float: right;
    margin-top: 3px;
  }
`;

const ScExpanderContent = styled.div`
  margin-bottom: 0;

  p,
  a {
    color: #868f97;
  }
`;

const Expander = ({
  title,
  content,
}: {
  title: string;
  content: React.ReactElement;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <ScExpander onClick={() => setExpanded(!expanded)}>
      <ScExpanderTitle>
        {title}

        {!expanded ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6667 6.66667H9.33333V1.33333C9.33333 0.979711 9.19286 0.640573 8.94281 0.390524C8.69276 0.140476 8.35362 0 8 0C7.64638 0 7.30724 0.140476 7.05719 0.390524C6.80714 0.640573 6.66667 0.979711 6.66667 1.33333V6.66667H1.33333C0.979711 6.66667 0.640573 6.80714 0.390524 7.05719C0.140476 7.30724 0 7.64638 0 8C0 8.35362 0.140476 8.69276 0.390524 8.94281C0.640573 9.19286 0.979711 9.33333 1.33333 9.33333H6.66667V14.6667C6.66667 15.0203 6.80714 15.3594 7.05719 15.6095C7.30724 15.8595 7.64638 16 8 16C8.35362 16 8.69276 15.8595 8.94281 15.6095C9.19286 15.3594 9.33333 15.0203 9.33333 14.6667V9.33333H14.6667C15.0203 9.33333 15.3594 9.19286 15.6095 8.94281C15.8595 8.69276 16 8.35362 16 8C16 7.64638 15.8595 7.30724 15.6095 7.05719C15.3594 6.80714 15.0203 6.66667 14.6667 6.66667Z"
              fill="white"
            />
          </svg>
        ) : null}
      </ScExpanderTitle>
      {expanded ? <ScExpanderContent>{content}</ScExpanderContent> : null}
    </ScExpander>
  );
};

export const FrequentlyAsked = () => {
  return (
    <ScRoot>
      <Container>
        <ScHorizontalDiv />
        <ScMain>
          <ScTitle>Frequently Asked Questions</ScTitle>

          <ScQuestionList>
            <Expander
              title="How is portfolio margin calculated?"
              content={
                <div>
                  <p>
                    Power Trade margin is denominated in USD. For the purposes
                    of accounting, the exchange assumes a constant USDC/USD rate
                    of 1.0
                  </p>
                  <p>
                    The Margin engine applies SPAN methodology to the entire
                    portfolio, with PV measured in USD terms.
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <a
                      href="/files/Power_Trade_Margin_Methodology_v1.pdf"
                      target="_blank"
                    >
                      Download the full overview of the Margin Methodology
                    </a>
                  </p>
                </div>
              }
            />
            <Expander
              title="What is the liquidation process and how’s account health related to that?"
              content={
                <div>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <a href="/files/Liquidation.pdf" target="_blank">
                      Download the liquidations process document here
                    </a>
                  </p>
                </div>
              }
            />
            <Expander
              title="What are the contract details for options?"
              content={
                <div>
                  <p>
                    PowerTrade offers European-style USD-settled options.
                    European-style options can only be exercised at expiry and
                    not before.
                  </p>
                  <p>
                    Options are priced in USD terms using exchange rates based
                    on a blend of respected price sources.
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <a
                      href="/files/PowerTrade_Options_Contract_Specifications.pdf"
                      target="_blank"
                    >
                      Download the options contract specifications document
                    </a>
                  </p>
                </div>
              }
            />
            <Expander
              title="What are the perpetuals contract specifications?"
              content={
                <div>
                  <p>
                    PowerTrade Perpetuals are a margin product that allows users
                    to take a view on a crypto asset price (BTC, ETH, etc.) and
                    profit on the asset’s appreciation or depreciation without
                    having to hold the actual crypto asset.
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <a
                      href="/files/PowerTrade_Perpetuals_Specifications.pdf"
                      target="_blank"
                    >
                      Download the perpetuals contract specifications document
                    </a>
                  </p>
                </div>
              }
            />
            <Expander
              title="What assets can be used as collateral?"
              content={
                <div>
                  <p>
                    Deposit stablecoins or any listed spot pairs (BTC, ETH, SOL,
                    AVAX) to trade BTC and ETH options.
                  </p>
                </div>
              }
            />
            <Expander
              title="What API protocols are supported?"
              content={
                <div>
                  <p>
                    PowerTrade offers WebSockets API, REST and FIX protocols.
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <a
                      href="https://support.power.trade/api/api-overview"
                      target="_blank"
                    >
                      Read our full API documentation here.
                    </a>
                  </p>
                </div>
              }
            />
          </ScQuestionList>
        </ScMain>
      </Container>
    </ScRoot>
  );
};
