import * as React from "react";
import { graphql, HeadFC } from "gatsby";

import { Layout } from "../components/Layout";
import { Institutional } from "../containers/institutional/institutional";
import { Edge } from "../containers/institutional/edge";
import { FrequentlyAsked } from "../containers/institutional/frequently-asked";
import { Onboard } from "../containers/institutional/onboard";

export const query = graphql`
  query getImageAndGetImageAndGetImageAndGetImage {
    institutional: file(relativePath: { eq: "v5/institutional.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const InstitutionalPage = ({ data }: any) => {
  return (
    <Layout>
      <Institutional data={data} />
      <Edge />
      <FrequentlyAsked />
      <Onboard />
    </Layout>
  );
};

export default InstitutionalPage;

export const Head: HeadFC = () => <title>Institutional Services</title>;
